/* src/styles/Welcome.module.css */

.sectionWelcome {
  background-color: #ffffff;
  color: #09151A;
  padding: 20px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentContainer {
  display: flex;
  flex-direction: row; /* Align items in a row for image and text */
  align-items: flex-start; /* Align items at the start */
  width: 100%;
  max-width: 1200px; /* Limit the maximum width for large screens */
  margin: 0 auto; /* Center the content horizontally */
  overflow: hidden; /* Prevent overflow */
}

.sliderContainer {
  flex: 1; /* Allow the slider container to take available space */
  max-width: 50%; /* Scale down to half of the container width */
  margin-right: 20px; /* Space between slider and text */
  padding-top: 50px;
}

.sliderImage {
  width: 100%; /* Ensure the image takes up the full width of the slider */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove extra space below the image */
}

.textContainer {
  flex: 1; /* Allow the text container to take available space */
  max-width: 50%; /* Scale down to half of the container width */
  padding: 200px 20px 0 20px; /* Add padding to move text down */
}

.sectionTitle {
  color: #edc531;
  margin-bottom: 20px;
  text-align: left; /* Align title to the left */
}

.paragraph {
  font-size: 1.2em;
  line-height: 1.6;
  text-align: left; /* Align text to the left */
}

.welcomeText {
  font-family: Courgette, cursive;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .contentContainer {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Center items */
  }

  .sliderContainer, .textContainer {
    max-width: 100%; /* Full width on smaller screens */
    margin-right: 0; /* Remove margin */
    padding: 0; /* Remove padding */
  }

  .sliderImage {
    width: 100%; /* Ensure the image scales down */
  }

  .sectionTitle {
    font-size: 1.5em;
  }

  .paragraph {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .sectionTitle {
    font-size: 1.2em;
  }

  .paragraph {
    font-size: 0.9em;
  }

  .sliderContainer{
    padding-top: 160px;
  }

  .textContainer{
    padding-top: 30px;
  }
}
