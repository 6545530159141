/* src/styles/Contact.module.css */

.topImageContainer {
  width: 100%;
  max-width: 1000px;
  overflow: hidden;
  padding-top: 10px;
  margin: 0 auto;
}

.topImage {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.contactSection {
  background-color: #ffffff;
  color: #09151A;
  padding: 20px;
  margin-top: 100px;
}

.faqSection {
  background-color: #ffffff;
  color: #09151A;
  padding: 20px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns layout */
  gap: 20px; /* Space between columns */
  font-family: Courgette, cursive;
}

.faqTitle {
  color: #003459;
  font-family: Courgette, cursive;
  margin-bottom: 20px;
  grid-column: span 2; /* Full width title */
}

.faqItem {
  text-align: left;
}

.faqItem strong {
  display: block;
  color: #003459;
  font-family: Courgette, cursive;
  margin-bottom: 5px;
}

.contactDetails {
  background-color: #ffffff;
  color: #09151A;
  border-radius: 8px;
}

.contactTitle {
  color: #003459;
  font-family: Courgette, cursive;
  margin-bottom: 20px;
}

.contactText {
  font-family: Courgette, cursive;
}

.contactSubTitle {
  color: #003459;
  font-family: Courgette, cursive;
  margin: 10px 0;
}

.contactPhone {
  font-family: Courgette, cursive;
  margin: 5px 0;
}

.specialMessage {
  background-color: #ffffff;
  color: #003459;
  padding-top: 10px;
  border-radius: 8px;
  margin-top: 20px;
  text-align: center;
  font-family: Courgette, cursive;
  font-size: 1.2em;
}

.gifContainer {
  max-width: 300px;
  margin: 20px auto;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 8px;
}

.duckGif {
  width: 100%;
  height: 100%;
  border: none;
}



/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .topImageContainer {
    padding-top: 125px;
    margin: 0;
  }

  .faqSection {
    grid-template-columns: 1fr; /* Single column layout */
    padding-top: 0px;
  }

  .specialMessage {
    font-size: 1em;
  }

  .gifContainer {
    max-width: 200px;
  }

}
