/* src/styles/Header.module.css */

.header {
  background-color: #003459;
  color: #ffffff;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.title {
  color: #edc531;
  text-align: center;
  font-family: Dancing Script, cursive;
  font-size: 1.5em;
}

.nav {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 10px;
}

.link {
  color: #edc531;
  text-decoration: none;
  margin: 5px;
  font-size: 1em;
  font-family: Permanent Marker, cursive;
}

/* Responsive styles */
@media (max-width: 768px) {
  .title {
    font-size: 1.2em;
  }

  .nav {
    flex-direction: column;
    align-items: center;
  }

  .link {
    font-size: 0.9em;
  }
}
