/* src/styles/Equipaje.module.css */
.equipajeSection {
  background-color: #ffffff;
  color: #09151A;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  gap: 20px;
}

.imageWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.imageTitle {
  font-family: Courgette, cursive;
  color: #003459;
  padding-top: 120px;
}

.imageTitle2 {
  font-family: Courgette, cursive;
  color: #003459;
  padding-top: 120px;
  padding-left: 150px;
}

.equipajeImage {
  max-width: 100%;
  height: auto;
  padding-top: 10px;
}

.equipajeImage1 {
  max-width: 100%;
  height: auto;
}

.imageDescription {
  color: #09151A;
  font-family: Courgette, cursive;
  text-align: center;
  margin-top: 10px;
}

.descriptionList {
  text-align: left;
  list-style-type: none;
  padding-left: 0;
}

.descriptionList li {
  margin-bottom: 10px;
  font-family: Courgette, cursive;
}

/* Responsive styles */
@media (max-width: 768px) {
  .imageContainer {
    flex-direction: column;
    align-items: center;
  }

  .equipajeSection {
    margin-top: 230px;
  }

  .imageTitle2 {
    padding-top: 1px;
    padding-left: 1px;
  }

  .imageTitle{
    margin-top: 0px;
    padding-top: 0px;
  }
}