/* src/styles/CheckIn.module.css */

.checkInSection {
  background-color: #ffffff;
  color: #09151A;
  padding: 20px;
  margin-top: 100px;
  display: flex;
  flex-direction: row; /* Align image and text side by side */
  justify-content: space-between;
  align-items: flex-start;
}

.checkInTextContainer {
  flex: 1; /* Allow the text container to take available space */
  max-width: 60%; /* Scale down to 60% of the container width */
  padding: 20px;
  text-align: left; /* Align text to the left */
}

.checkInTitle {
  color: #edc531;
  font-family: Courgette, cursive;
}

.checkInText {
  font-family: Courgette, cursive;
  line-height: 1.6;
}

.checkInLinkContainer {
  text-align: center; /* Center the link within this container */
}

.checkInLink {
  color: #edc531; /* Text color */
  font-weight: bold;
  text-decoration: none;
  font-size: 1.5em; /* Increase font size */
  display: inline-block; /* Make it an inline-block element to adjust size to text */
  margin: 10px 0; /* Add margin for spacing */
  background-color: #003459; /* Background color for the box */
  border: 2px solid #003459; /* Border color matching the background */
  padding: 5px 15px; /* Add padding inside the box (smaller padding) */
  border-radius: 5px; /* Optional: Add rounded corners */
}

.checkInImageContainer {
  flex: 1; /* Allow the image container to take available space */
  max-width: 40%; /* Scale down to 40% of the container width */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.checkInImage {
  max-width: 100%; /* Ensure the image does not exceed the container's width */
  height: auto;
}

.boldHugeText {
  font-size: 1.2em; /* Increase font size */
  font-weight: bold; /* Bold font */
  color: red; /* Optional: Add color to make it stand out more */
  display: block; /* Make it a block element for spacing */
  margin: 10px 0; /* Add margin for spacing */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .checkInSection {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Center items */
  }

  .checkInTextContainer {
    max-width: 100%; /* Full width on smaller screens */
    padding: 20px 10px; /* Add padding for responsive view */
    padding-top: 150px; /* Specific top padding */
  }

  .checkInImageContainer {
    max-width: 100%; /* Full width on smaller screens */
    padding: 10px;
  }
}
