/* src/styles/DateLocation.module.css */

.topImageContainer {
  width: 100%;
  max-width: 1000px;
  overflow: hidden;
  padding-top: 90px;
}

.topImage {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.sectionDateLocation {
  background-color: #ffffff;
  color: #09151A;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dateLocationContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1000px;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
}

.textContainer {
  width: 40%;
  text-align: left;
  padding: 50px 20px 0 20px;
}

.imageContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px;
}

.howToGetTitle {
  margin-bottom: 10px;
  color: #edc531;
  font-family: Courgette, cursive;
}

.howToGetImage {
  max-width: 100%;
  /* Increase the size of the image */
  height: auto;
}

.mapContainer {
  width: 100%;
  max-width: 1000px;
  height: 400px;
  display: flex;
  justify-content: center;
  padding: 0 20px;
}

.dateLocationTitle {
  color: #edc531;
  margin-bottom: 20px;
  text-align: left;
  font-family: Courgette, cursive;
}

.contentTextLabel {
  color: #edc531;
  font-weight: bold;
  font-family: Courgette, cursive;
}

.descriptionTextLabel {
  color: #09151A;
  font-family: Courgette, cursive;
}

.dateLocationDetails {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dateLocationDetails p {
  margin: 0;
}

.dateLocationDetails .dateLocationText {
  flex: 1;
}

.dateLocationSala {
  width: 100%;
  text-align: left;
}

/* Responsive styles */
@media (max-width: 768px) {

  .topImageContainer {
    padding-top: 205px;
  }

  .dateLocationContainer {
    flex-direction: column;
    align-items: center;
    padding: 0px 20px 0 20px;
  }

  .textContainer,
  .mapContainer,
  .imageContainer {
    width: 100%;
  }

  .imageContainer {
    padding: 0px;
  }

  .dateLocationTitle,
  .contentTextLabel,
  .descriptionTextLabel {
    text-align: center;
    /* Align text content to the center for responsive */
  }

  .dateLocationDetails {
    flex-direction: column;
    align-items: flex-start;
    /* Align items to the start for better alignment */
    white-space: nowrap;
    /* Ensure text appears in one line */
  }

}